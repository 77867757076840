import React from "react";
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
function Navbar({ siteTitle }) {
  return (
    <section>
      <div className="container px-4 mx-auto">
        <nav className="flex items-center py-6">
          <Link to="/">
            <StaticImage
              src="../images/designtypist-logo.png"
              alt="DesignTypist Logo"
              width={100}
              height={100}
            />
          </Link>
          <div className="lg:hidden ml-auto">
            <Link className="navbar-burger flex items-center py-2 px-3 text-blue-600 hover:text-blue-700 rounded border border-blue-200 hover:border-blue-300"
              to="/">
              <svg
                className="fill-current h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </Link>
          </div>
          <ul className="hidden lg:flex self-center items-center space-x-12 ml-auto mr-12">
            <li>
              <Link className="text-sm text-blueGray-400 hover:text-blueGray-500" 
                to="/">Home</Link>
            </li>
            <li>
              <Link className="text-sm text-blueGray-400 hover:text-blueGray-500" 
                to="/about">About</Link>
            </li>
            <li>
              <Link className="text-sm text-blueGray-400 hover:text-blueGray-500" 
                to="/news">News</Link>
            </li>
          </ul>
          <div className="hidden lg:block">
            <Link className="inline-block px-4 py-3 text-xs font-semibold leading-none bg-blue-600 hover:bg-blue-700 text-white rounded"
              to="/contact">Contact Me</Link>
          </div>
        </nav>
      </div>
      <div className="hidden navbar-menu relative z-50">
        <div className="navbar-backdrop fixed inset-0 bg-blueGray-800 opacity-25" />
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <Link
              className="mr-auto text-3xl font-semibold leading-none"
              to="/"
            >
              DesignTypist
            </Link>
            <button className="navbar-close">
              <svg
                className="h-6 w-6 text-blueGray-400 cursor-pointer hover:text-blueGray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <ul>
              <li className="mb-1">
                <Link
                  className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                  to="/news"
                >
                  News
                </Link>
              </li>
            </ul>
            <div className="mt-4 pt-6 border-t border-blueGray-100">
              <Link
                className="block px-4 py-3 mb-3 text-xs text-center font-semibold leading-none bg-blue-600 hover:bg-blue-700 text-white rounded"
                to="/"
              >
                Contact Me
              </Link>
            </div>
          </div>
          <div className="mt-auto">
            <p className="my-4 text-xs text-blueGray-400">
              <span>Get in Touch </span>
              <a
                className="text-blue-600 hover:text-blue-600 underline"
                href="#"
              >
                hello@designtypist.com
              </a>
            </p>
            <a className="inline-block px-1" href="https://facebook.com/designtypist" target="_blank">
              <img src="metis-assets/icons/facebook-blue.svg" alt />
            </a>
            <a className="inline-block px-1" href="https://twitter.com/designtypist" target="_blank">
              <img src="metis-assets/icons/twitter-blue.svg" alt />
            </a>
            <a className="inline-block px-1" href="https://instagram.com/designtypist" target="_blank">
              <img src="metis-assets/icons/instagram-blue.svg" alt />
            </a>
          </div>
        </nav>
      </div>
    </section>
  );
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar;