import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faBehance, faGithub, } 
  from '@fortawesome/free-brands-svg-icons'

function SocialMedia() {
  return (
    <div className="order-first lg:order-last -mx-2 mb-4 lg:mb-0">
        <a className="inline-block px-2" href="https://facebook.com/designtypist" target="_blank">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a className="inline-block px-2" href="https://twitter.com/designtypist" target="_blank">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a className="inline-block px-2" href="https://instagram.com/designtypist" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a className="inline-block px-2" href="https://behance.net/designtypist" target="_blank">
            <FontAwesomeIcon icon={faBehance} size="2x" />
        </a>
        <a className="inline-block px-2" href="https://github.com/designtypist" target="_blank">
            <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
    </div>
  );
}

export default SocialMedia;