import React from "react";
import { Link } from "gatsby"
import SocialMedia from "./SocialMedia"

function Footer() {
  return (
    <section className="py-20">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap mb-12 lg:mb-20 -mx-3 text-center lg:text-left">
          <div className="w-full lg:w-1/5 px-3 mb-6 lg:mb-0">
            <Link
              className="inline-block mx-auto lg:mx-0 text-3xl font-semibold leading-none"
              to="/"
            >
              DesignTypist
            </Link>
          </div>
          <div className="w-full lg:w-2/5 px-3 mb-8 lg:mb-0">
            <p className="mb-2 lg:mb-4 lg:text-lg font-bold font-heading text-blueGray-800">
              About
            </p>
            <p className="max-w-md mx-auto lg:max-w-full lg:mx-0 lg:pr-32 lg:text-lg text-blueGray-400 leading-relaxed italic">
              "Happily building for healthier future"
            </p>
          </div>
          <div className="w-full lg:w-1/5 px-3 mb-8 lg:mb-0">
            <p className="mb-2 lg:mb-4 lg:text-lg font-bold font-heading text-blueGray-800">
              Menu
            </p>
            <ul className="lg:text-lg text-blueGray-400">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
              <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/5 px-3">
            <p className="mb-2 lg:mb-4 lg:text-lg font-bold font-heading text-blueGray-800">
              Contacts
            </p>
            <p className="lg:text-lg text-blueGray-400">info@designtypist.com</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center lg:justify-between">
          <p className="text-xs text-blueGray-400">
            © 2021. All rights reserved.
          </p>
          <SocialMedia />
        </div>
      </div>
    </section>
  );
}

export default Footer;